import i18n from '@/i18n'
import ReportHeading from '@/Utils/report-head'
import pdfMake from 'pdfmake/build/pdfmake'
import pdfFontsBn from 'pdfmake/build/vfs_fonts_bn'
import pdfFontsEn from 'pdfmake/build/vfs_fonts_en'
import { dateFormat } from '@/Utils/fliter'

const exportPdfDetails = async (baseUrl, uri = '/report-heading/detail', orgId, reportTitle, data, activityData, vm, titleData) => {
  try {
      // Translation
      if (i18n.locale === 'bn') {
        pdfMake.vfs = pdfFontsBn.pdfMake.vfs
      } else {
          pdfMake.vfs = pdfFontsEn.pdfMake.vfs
      }

      // API Call
      const reportHeadData = await ReportHeading.getReportHead(baseUrl, uri, orgId)

      // PDF Content (reportHeadData)
      const pdfContent = [
          { columns: reportHeadData.reportHeadColumn },
          { text: reportHeadData.orgName, style: 'org', alignment: 'center' },
          { text: reportHeadData.projectName, style: 'address', alignment: 'center' },
          { text: reportHeadData.address, style: 'address', alignment: 'center' },
          { text: reportTitle, style: 'hh', alignment: 'center' }
        ]

        pdfContent.push(
            {
                table: {
                  headerRows: 0,
                  widths: ['25%', '25%', '25%', '25%'],
                  body: [
                    // 1
                    [
                      { text: vm.$t('research_manage.project_title'), style: 'th', alignment: 'center' },
                      { text: (vm.$i18n.locale === 'en' ? data.project_title : data.project_title_bn), style: 'td', alignment: 'center' },
                      { text: vm.$t('research_manage.project_id'), style: 'th', alignment: 'center' },
                      { text: data.proposal_auto_id, style: 'td', alignment: 'center' }
                    ],
                    [
                      { text: vm.$t('external_research.start_date'), style: 'th', alignment: 'center' },
                      { text: data.from_date, style: 'td', alignment: 'center' },
                      { text: vm.$t('external_research.end_date'), style: 'th', alignment: 'center' },
                      { text: data.to_date, style: 'td', alignment: 'center' }
                    ]
                  ]
                }
            }
          )
          pdfContent.push(
            { text: '', style: 'empty_space' }
          )

      //
      // List Data
      const allRows = [
        [
          { text: vm.$t('globalTrans.sl_no'), style: 'th', alignment: 'center', bold: true },
          { text: vm.$t('external_research.activity_name'), style: 'th', alignment: 'center' },
          { text: vm.$t('external_research.start_date'), style: 'th', alignment: 'center' },
          { text: vm.$t('external_research.end_date'), style: 'th', alignment: 'center' },
          { text: vm.$t('globalTrans.status'), style: 'th', alignment: 'center' }
        ]
      ]

      activityData.forEach((data, index) => {
        allRows.push([
          { text: vm.$n(index + 1), alignment: 'center', style: 'td' },
          { text: data.activity_name, alignment: 'center', style: 'td' },
          { text: dateFormat(data.start_date), alignment: 'center', style: 'td' },
          { text: dateFormat(data.end_date), alignment: 'center', style: 'td' },
          { text: data.status, alignment: 'center', style: 'td' }
        ])
      })

      //
      pdfContent.push({
        table: {
          headerRows: 1,
          widths: ['7%', '*', '10%', '10%', '*'],
          body: allRows
        }
      })

      var docDefinition = {
        content: pdfContent,
        pageSize: 'A4',
        pageOrientation: 'landscape',
        styles: {
            th: {
              fontSize: 10,
              margin: [3, 3, 3, 3],
              bold: true
            },
            td: {
              fontSize: 10,
              margin: [3, 3, 3, 3]
            },
            header: {
              margin: [0, 1, 0, 15],
              fontSize: 16,
              alignment: 'center'
            },
            header2: {
              border: 0,
              fontSize: 14,
              margin: [0, 0, 0, 1]
            },
            address2: {
              border: 0,
              fontSize: 12,
              bold: true,
              margin: [0, 0, 0, 5]
            },
            empty_space: {
              margin: [10, 10, 10, 10]
            }
        }
      }

      pdfMake.createPdf(docDefinition, null, null, null).print()
    } catch (error) {
      if (error) {
      }
    }
  }

  export default {
    exportPdfDetails
  }
