<template>
  <b-container fluid class="text-dark">
    <b-row>
      <b-col lg="12" sm="12">
        <iq-card>
          <template v-slot:headerAction>
              <b-button variant="primary" @click="pdfExport" class="mr-2">
                  {{  $t('globalTrans.export_pdf') }}
              </b-button>
          </template>
          <template v-slot:body>
                <b-overlay :show="loader">
                  <b-table-simple bordered small hover>
                    <tbody>
                      <b-tr>
                          <b-th style="width:20%;">{{$t('external_research.project_id')}}</b-th>
                          <b-td style="width:30%;">{{ data.proposal_auto_id }}</b-td>
                          <b-th style="width:20%;">{{$t('external_research.project_title')}}</b-th>
                          <b-td style="width:30%;">{{ $i18n.locale === 'en' ? data.project_title : data.project_title_bn }} </b-td>
                      </b-tr>
                      <b-tr>
                          <b-th style="width:20%;">{{$t('external_research.start_date')}}</b-th>
                          <b-td style="width:30%;">{{ data.from_date | dateFormat }} </b-td>
                          <b-th style="width:20%;">{{$t('external_research.end_date')}}</b-th>
                          <b-td style="width:30%;">{{ data.to_date | dateFormat }} </b-td>
                      </b-tr>
                    </tbody>
                  </b-table-simple>
                   <b-row>
                    <b-col md="12" class="table-responsive">
                      <b-table thead-class="bg-primary" bordered hover :items="activity" :fields="columns" aria-hidden="loading | listReload ? 'true' : null" :emptyText="$t('globalTrans.noDataFound')" show-empty>

                        <template v-slot:cell(index)="data">
                          {{ $n(data.index + 1) }}
                        </template>

                        <template v-slot:cell(from_date)="data">
                          {{ data.item.from_date | dateFormat }}
                        </template>

                        <template v-slot:cell(to_date)="data">
                          {{ data.item.to_date | dateFormat }}
                        </template>

                        <template v-slot:cell(status)="data">
                          {{ data.item.status }}
                        </template>

                        <template v-slot:cell(action)="data">
                          <b-button v-b-modal.modal-1 variant=" iq-bg-success mr-1 mb-1" size="sm" title="View" @click="view(data.item)">
                            <i class="ri-eye-line m-0"></i>
                          </b-button>
                        </template>

                      </b-table>
                    </b-col>
                  </b-row>
                </b-overlay>
          </template>
        </iq-card>
      </b-col>
    </b-row>
  </b-container>
</template>
<script>
import RestApi, { agriResearchServiceBaseUrl } from '@/config/api_config'
import { activityDetailsApi, activityProgressReportApi } from '../../api/routes'
import ExportPdf from './export-details-pdf'
export default {
    props: ['id'],
     created () {
        if (this.id) {
            this.getData()
            // this.data = tmp
            this.getActivity()
            if (this.data.status === 0) {
              this.status = this.$t('globalTrans.not_started')
            } else if (this.data.status === 2) {
              this.status = this.$t('globalTrans.ongoing')
            } else {
              this.status = this.$t('globalTrans.completed')
            }
        }
    },
    computed: {
      columns () {
      const labels = [
          { label: this.$t('globalTrans.sl_no'), class: 'text-left' },
          { label: this.$t('external_research.activity_name'), class: 'text-left' },
          { label: this.$t('external_research.start_date'), class: 'text-left' },
          { label: this.$t('external_research.end_date'), class: 'text-left' },
          { label: this.$t('globalTrans.status'), class: 'text-center' }
        ]

      let keys = []

      if (this.$i18n.locale === 'bn') {
        keys = [
          { key: 'index' },
          { key: 'activity_name_bn' },
          { key: 'start_date' },
          { key: 'end_date' },
          { key: 'status' }
        ]
      } else {
        keys = [
          { key: 'index' },
          { key: 'activity_name' },
          { key: 'start_date' },
          { key: 'end_date' },
          { key: 'status' }
        ]
      }

      return labels.map((item, index) => {
        return Object.assign(item, keys[index])
      })
    }
    },
    data () {
        return {
            agriResearchServiceBaseUrl: agriResearchServiceBaseUrl,
            loader: false,
            data: {},
            activity: [],
            status: ''
        }
    },
    methods: {
        async getData () {
          // const tmpData = this.$store.state.list.find(item => item.id === this.id)
          // return JSON.parse(JSON.stringify(tmpData))
          this.loader = true
          let result = null
          result = await RestApi.getData(agriResearchServiceBaseUrl, activityProgressReportApi, { id: this.id })
          if (result.success) {
            this.data = result.data[0]
          }
        },
        async pdfExport () {
          this.loader = true
          const reportTitle = this.$t('research_manage.activity_report') + ' ' + this.$t('globalTrans.details')
          await ExportPdf.exportPdfDetails(agriResearchServiceBaseUrl, '/report-heading/detail', 4, reportTitle, this.data, this.activity, this)
          this.loader = false
        },
        async getActivity () {
          this.loader = true
          let result = null
          result = await RestApi.getData(agriResearchServiceBaseUrl, activityDetailsApi, { id: this.id })
          if (result.success) {
            this.activity = this.getRelationalData(result.data)
          }
          this.loader = false
        },
        getRelationalData (data) {
          const listData = data.map(item => {
          //   // Relational
            let status = ''
            if (item.activity_status === 0) {
              status = this.$t('globalTrans.not_started')
            } else {
              status = this.$t('globalTrans.ongoing')
            }
            // Evaluate
            const evaluationData = {
              activity_name: item.activity_name === undefined ? null : item.activity_name,
              activity_name_bn: item.activity_name_bn === undefined ? null : item.activity_name_bn,
              status: status
            }
          //   // Assign
            return Object.assign({}, item, evaluationData)
          })
          return listData
    }
  }
}
</script>
<style scoped>
.request_dtls_wrapper tr th {
  width: 20%;
}
.request_dtls_wrapper tr td {
  width: 30%;
}
</style>
