import i18n from '@/i18n'
import ReportHeading from '@/Utils/report-head'
import pdfMake from 'pdfmake/build/pdfmake'
import pdfFontsBn from 'pdfmake/build/vfs_fonts_bn'
import pdfFontsEn from 'pdfmake/build/vfs_fonts_en'
import { dateFormat } from '@/Utils/fliter'

const exportPdfDetails = async (baseUrl, uri = '/report-heading/detail', orgId, reportTitle, reportData, vm, titleData) => {
  try {
      // Translation
      if (i18n.locale === 'bn') {
        pdfMake.vfs = pdfFontsBn.pdfMake.vfs
      } else {
          pdfMake.vfs = pdfFontsEn.pdfMake.vfs
      }

      // API Call
      const reportHeadData = await ReportHeading.getReportHead(baseUrl, uri, orgId)

      // PDF Content (reportHeadData)
      const pdfContent = [
          { columns: reportHeadData.reportHeadColumn },
          { text: reportHeadData.orgName, style: 'org', alignment: 'center' },
          { text: reportHeadData.projectName, style: 'address', alignment: 'center' },
          { text: reportHeadData.address, style: 'address', alignment: 'center' },
          { text: reportTitle, style: 'hh', alignment: 'center' }
        ]

      //
      // List Data
      const allRows = [
        [
          { text: vm.$t('globalTrans.sl_no'), style: 'th', alignment: 'center', bold: true },
          { text: vm.$t('external_research.proposal_id'), style: 'th', alignment: 'center' },
          { text: vm.$t('external_research.project_title'), style: 'th', alignment: 'center' },
          { text: vm.$t('external_research.start_date'), style: 'th', alignment: 'center' },
          { text: vm.$t('external_research.end_date'), style: 'th', alignment: 'center' },
          { text: vm.$t('research_manage.duration'), style: 'th', alignment: 'center' },
          { text: vm.$t('globalTrans.status'), style: 'th', alignment: 'center' }
        ]
      ]

      reportData.forEach((data, index) => {
        allRows.push([
          { text: vm.$n(index + 1), alignment: 'center', style: 'td' },
          // { text: vm.getOrgName(data.org_id), alignment: 'center', style: 'td' },
          { text: data.proposal_auto_id, alignment: 'center', style: 'td' },
          { text: data.project_title, alignment: 'center', style: 'td' },
          { text: dateFormat(data.from_date), alignment: 'center', style: 'td' },
          { text: dateFormat(data.to_date), alignment: 'center', style: 'td' },
          { text: data.duration, alignment: 'center', style: 'td' },
          { text: data.status, alignment: 'center', style: 'td' }
        ])
      })

      //
      pdfContent.push({
        table: {
          headerRows: 1,
          widths: ['7%', '15%', '15%', '15%', '15%', '20%', '13%'],
          body: allRows
        }
      })

      var docDefinition = {
        content: pdfContent,
        pageSize: 'A4',
        pageOrientation: 'landscape',
        styles: {
            th: {
              fontSize: 10,
              margin: [3, 3, 3, 3],
              bold: true
            },
            td: {
              fontSize: 10,
              margin: [3, 3, 3, 3]
            },
            header: {
              margin: [0, 1, 0, 15],
              fontSize: 16,
              alignment: 'center'
            },
            header2: {
              border: 0,
              fontSize: 14,
              margin: [0, 0, 0, 1]
            },
            address2: {
              border: 0,
              fontSize: 12,
              bold: true,
              margin: [0, 0, 0, 5]
            },
            empty_space: {
              margin: [10, 10, 10, 10]
            }
        }
      }

      pdfMake.createPdf(docDefinition, null, null, null).print()
    } catch (error) {
      if (error) {
      }
    }
  }

  export default {
    exportPdfDetails
  }
