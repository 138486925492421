<template>
  <b-container fluid>
    <iq-card>
      <template v-slot:headerTitle>
        <h4 class="card-title">{{ $t('research_manage.activity_report') + ' ' + $t('globalTrans.search') }}</h4>
      </template>
      <template v-slot:body>
        <b-overlay :show="loader">
          <b-row>
              <b-col xs="12" sm="12" md="7" lg="7" xl="6">
                  <b-form-group
                  class="row"
                  label-cols-sm="3"
                  :label="$t('external_research.thematic_area')"
                  label-for="budget_head"
                  >
                  <b-form-select
                    plain
                    v-model="search.thematic_area_id"
                    id="thematic_area_id"
                    @change="getProjectList"
                    :options="thematicAreaList"
                    >
                    <template v-slot:first>
                        <b-form-select-option :value="0">{{$t('globalTrans.select')}}</b-form-select-option>
                    </template>
                    </b-form-select>
                  </b-form-group>
              </b-col>
              <b-col xs="12" sm="12" md="7" lg="7" xl="6">
                  <b-form-group
                  class="row"
                  label-cols-sm="3"
                  :label="$t('external_research.project_title')"
                  label-for="project_title"
                  >
                  <b-form-select
                    plain
                    v-model="search.project_id"
                    id="project_id"
                    :options="projectList"
                    >
                    <template v-slot:first>
                        <b-form-select-option :value="0">{{$t('globalTrans.select')}}</b-form-select-option>
                    </template>
                    </b-form-select>
                  </b-form-group>
              </b-col>
          </b-row>
          <b-row>
            <b-col xs="12" sm="12" md="7" lg="7" xl="6">
                  <b-form-group
                      class="row"
                      label-cols-sm="3"
                      label-for="from_date"
                    >
                      <template v-slot:label>
                        {{ $t('globalTrans.from_date') }}
                      </template>
                      <b-form-input
                        id="from_date"
                        v-model="search.from_date"
                        :placeholder="$t('globalTrans.from_date')"
                        ></b-form-input>
                  </b-form-group>
              </b-col>
              <b-col xs="12" sm="12" md="7" lg="7" xl="6">
                  <b-form-group
                      class="row"
                      label-cols-sm="3"
                      label-for="from_date"
                    >
                      <template v-slot:label>
                        {{ $t('globalTrans.to_date') }}
                      </template>
                      <b-form-input
                        id="to_date"
                        v-model="search.to_date"
                        :placeholder="$t('globalTrans.to_date')"
                        ></b-form-input>
                  </b-form-group>
              </b-col>
          </b-row>
          <b-row>
              <b-col xs="12" sm="12" md="3" lg="3" xl="2">
                  <b-button type="button" variant="primary" @click="searchData">{{ $t('globalTrans.search')}}</b-button>
              </b-col>
          </b-row>
        </b-overlay>
      </template>
    </iq-card>
    <iq-card v-if="isShow">
      <template v-slot:headerTitle>
        <h4 class="card-title">{{ $t('research_manage.activity_report') + ' ' + $t('globalTrans.list') }}</h4>
      </template>
      <template v-slot:headerAction>
          <b-button @click="pdfExport" class="btn_add_new">
            <i class="far fa-file-pdf"></i>{{  $t('globalTrans.export_pdf') }}
          </b-button>
      </template>
      <template v-slot:body>
          <b-row>
            <b-col md="12" class="table-responsive">
              <b-table thead-class="bg-primary" bordered hover :items="reportData" :fields="columns" aria-hidden="loading | listReload ? 'true' : null" :emptyText="$t('globalTrans.noDataFound')" show-empty>

                <template v-slot:cell(index)="data">
                  {{ $n(data.index + 1) }}
                </template>

                <template v-slot:cell(from_date)="data">
                  {{ data.item.from_date | dateFormat }}
                </template>

                 <template v-slot:cell(to_date)="data">
                  {{ data.item.to_date | dateFormat }}
                </template>

                 <template v-slot:cell(duration)="data">
                  {{ data.item.duration }}
                </template>

                <template v-slot:cell(status)="data">
                  {{ data.item.status }}
                </template>

                <template v-slot:cell(action)="data">
                  <b-button v-b-modal.modal-1 variant=" iq-bg-success mr-1 mb-1" size="sm" title="View" @click="view(data.item)">
                    <i class="ri-eye-line m-0"></i>
                  </b-button>
                </template>
              </b-table>
            </b-col>
          </b-row>
      </template>
    </iq-card>
    <b-modal id="modal-1" size="xl" :title="formTitle" ok-only ok-variant="danger">
      <Details :id="viewItemId" :key="viewItemId"/>
    </b-modal>
  </b-container>
</template>
<script>

import RestApi, { agriResearchServiceBaseUrl } from '@/config/api_config'
import { activityProgressReportApi, projectListApi } from '../../api/routes'
import ModalBaseMasterList from '@/mixins/modal-base-master-list'
import Details from './Details'
import moment from 'moment'
import ExportPdf from './export-report-pdf'
import flatpickr from 'flatpickr'

export default {
  mixins: [ModalBaseMasterList],
  components: {
    Details
    // ListReportHead
  },
  data () {
    return {
      rows: [],
      item: '',
      isShow: false,
      loader: false,
      loadProject: false,
      search: {
        project_id: '0',
        thematic_area_id: '0',
        from_date: '',
        to_date: ''
      },
      projectList: [],
      reportData: [],
      viewItemId: 0
    }
  },
  created () {
  },
  mounted () {
    flatpickr('#from_date', {})
    flatpickr('#to_date', {})
  },
  computed: {
      formTitle () {
        return this.$t('research_manage.project_activity') + ' ' + this.$t('globalTrans.details')
    },
    currentLocale () {
      return this.$i18n.locale
    },
     thematicAreaList: function () {
        const objectData = this.$store.state.AgriResearch.commonObj.thematicAreaList.filter(item => item.status === 1)
        return objectData.map((obj, key) => {
                if (this.$i18n.locale === 'bn') {
                    return { value: obj.value, text: obj.text_bn }
                } else {
                    return { value: obj.value, text: obj.text_en }
                }
            })
    },
    columns () {
      const labels = [
          { label: this.$t('globalTrans.sl_no'), class: 'text-left' },
          { label: this.$t('external_research.proposal_id'), class: 'text-left' },
          { label: this.$t('external_research.project_title'), class: 'text-left' },
          { label: this.$t('external_research.start_date'), class: 'text-left' },
          { label: this.$t('external_research.end_date'), class: 'text-left' },
          { label: this.$t('research_manage.duration'), class: 'text-left' },
          { label: this.$t('globalTrans.status'), class: 'text-center' },
          { label: this.$t('globalTrans.action'), class: 'text-center' }
        ]

      let keys = []

      if (this.$i18n.locale === 'bn') {
        keys = [
          { key: 'index' },
          { key: 'proposal_auto_id' },
          { key: 'project_title_bn' },
          { key: 'from_date' },
          { key: 'to_date' },
          { key: 'duration' },
          { key: 'status' },
          { key: 'action' }
        ]
      } else {
        keys = [
          { key: 'index' },
          { key: 'proposal_auto_id' },
          { key: 'project_title' },
          { key: 'from_date' },
          { key: 'to_date' },
          { key: 'duration' },
          { key: 'status' },
          { key: 'action' }
        ]
      }

      return labels.map((item, index) => {
        return Object.assign(item, keys[index])
      })
    }
  },
  methods: {
    async getProjectList () {
      this.loader = true
      let result = null
      result = await RestApi.getData(agriResearchServiceBaseUrl, projectListApi, { thematic_area_id: this.search.thematic_area_id })
      if (result.success) {
          if (result.data !== null) {
              this.projectList = result.data.map((obj, key) => {
              if (this.$i18n.locale === 'bn') {
                  return { value: obj.value, text: obj.text_bn }
              } else {
                  return { value: obj.value, text: obj.text_en }
              }
            })
          }
      }
      this.loader = false
    },
    searchData () {
      this.loadData()
    },
    view (item) {
      this.viewItemId = item.id
    },
    async pdfExport () {
        this.loader = true
        const reportTitle = this.$t('research_manage.activity_report')
        await ExportPdf.exportPdfDetails(agriResearchServiceBaseUrl, '/report-heading/detail', 4, reportTitle, this.reportData, this)
        this.loader = false
    },
    async loadData () {
      this.isShow = false
      this.loader = true
      const params = Object.assign({}, this.search, { page: this.pagination.currentPage, per_page: this.pagination.perPage })
      this.$store.dispatch('mutateCommonProperties', { loading: true, listReload: false })
      let result = null
      result = await RestApi.getData(agriResearchServiceBaseUrl, activityProgressReportApi, params)
      if (result.success) {
          this.isShow = true
          this.reportData = this.getRelationalData(result.data)
      }
      this.$store.dispatch('mutateCommonProperties', { loading: false, listReload: false })
      this.loader = false
    },
    getRelationalData (data) {
      const listData = data.map(item => {
        const start = moment(item.from_date, 'YYYY-MM-DD')
        const end = moment(item.to_date, 'YYYY-MM-DD')
        const days = end.diff(start, 'days')
        const months = end.diff(start, 'months')
        const years = end.diff(start, 'years')
        const duration = years + ' years, ' + months % 12 + ' months, ' + days % 30 + ' days'
      //   // Relational
        let status = ''
        if (item.status > 0) {
          status = this.$t('globalTrans.pending')
        } else {
          status = this.$t('globalTrans.completed')
        }
        // Evaluate
        const evaluationData = {
          project_title: item.project_title === undefined ? null : item.project_title,
          project_title_bn: item.project_title_bn === undefined ? null : item.project_title_bn,
          duration: duration,
          status: status
        }
      //   // Assign
        return Object.assign({}, item, evaluationData)
      })
      return listData
    }
  }
}
</script>
